


.cssAngle_ce61vs7{width:16px;height:16px;min-width:16px;min-height:16px;display:none;}
.StyledCategoryHeader_s161g10q{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;margin:0;gap:16px;}
.StyledCategoryImage_s1s1xsld{display:block;position:relative;margin-bottom:24px;border-radius:50px;width:80px;min-width:80px;height:80px;min-height:80px;overflow:hidden;margin-left:0;margin-right:0;}.StyledCategoryImage_s1s1xsld .StyledEntityImage_sk1fjhn{width:100%;height:100%;}
.StyledCategoryTitle_snb6src.Paragraph16_payptdg.TypographyBase_t102nrcx{line-height:140%;font-weight:bold;margin:0 0 16px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.StyledCategoryTitle_snb6src.Paragraph16_payptdg.TypographyBase_t102nrcx:hover,.StyledCategoryTitle_snb6src.Paragraph16_payptdg.TypographyBase_t102nrcx:active{color:#BC39E5;}.StyledCategoryTitle_snb6src.Paragraph16_payptdg.TypographyBase_t102nrcx .cssIcon_cc7xf1o{margin-right:10px;}.StyledCategoryTitle_snb6src.Paragraph16_payptdg.TypographyBase_t102nrcx .Span_s1a8gko7{-webkit-flex:1;-ms-flex:1;flex:1;}.StyledCategoryTitle_snb6src.Paragraph16_payptdg.TypographyBase_t102nrcx .StyledLinkBase_s1ktnduy{color:inherit;}@media (max-width:768px){.StyledCategoryTitle_snb6src.Paragraph16_payptdg.TypographyBase_t102nrcx{font-family:montserrat,sans-serif;font-size:.88rem;line-height:200%;font-weight:500;margin:0;}}


.StyledCategory_s1x9vldu .cssButtonBackward_cszg5sq{color:#7A7A7A;background:transparent;display:none;margin:0 auto 20px 20px;}.StyledCategory_s1x9vldu .cssButtonBackward_cszg5sq:hover,.StyledCategory_s1x9vldu .cssButtonBackward_cszg5sq:active{background:transparent;color:#000000;}.StyledCategory_s1x9vldu .StyledList_s1u8kxrv{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}.StyledCategory_s1x9vldu .StyledList_s1u8kxrv .StyledCategoryTitle_snb6src{font-family:montserrat,sans-serif;font-size:.88rem;line-height:200%;font-weight:500;margin:0;color:#000000;margin-bottom:4px;}.StyledCategory_s1x9vldu .StyledList_s1u8kxrv .StyledCategoryTitle_snb6src:hover,.StyledCategory_s1x9vldu .StyledList_s1u8kxrv .StyledCategoryTitle_snb6src:active{color:#BC39E5;}@media (max-width:768px){.StyledCategory_s1x9vldu .StyledList_s1u8kxrv .StyledCategoryTitle_snb6src{font-family:montserrat,sans-serif;font-size:.88rem;line-height:200%;font-weight:500;margin:0;}}.StyledCategory_s1x9vldu .StyledList_s1u8kxrv .StyledList_s1u8kxrv .StyledCategoryTitle_snb6src{color:#000000;}.StyledCategory_s1x9vldu .StyledList_s1u8kxrv .StyledList_s1u8kxrv .StyledCategoryTitle_snb6src:hover,.StyledCategory_s1x9vldu .StyledList_s1u8kxrv .StyledList_s1u8kxrv .StyledCategoryTitle_snb6src:active{color:#BC39E5;}.StyledCategory_s1x9vldu .StyledLinkBase_s1ktnduy{color:inherit;width:100%;}.StyledCategory_s1x9vldu > .StyledCategoryTitle_snb6src > .ButtonBase_buxzx4h{display:none;color:#7A7A7A;}.StyledCategory_s1x9vldu > .StyledCategoryImage_s1s1xsld{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}@media (max-width:768px){.StyledCategory_s1x9vldu .cssAngle_ce61vs7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}
.StyledCategoryQty_stbgogm.Span_s1a8gko7{font-family:montserrat,sans-serif;font-size:.75rem;line-height:200%;font-weight:500;margin:0;color:#7A7A7A;margin:0 0 0 6px;}

.StyledCategoryContainer_sudjyuf{padding:30px 10px 30px 20px;border-right:1px solid #DEDEDE;border-bottom:1px solid #DEDEDE;}.StyledCategoryContainer_sudjyuf .StyledCategoryContainer_sudjyuf{padding:0 0 0 16px;border:none;width:100%;}.StyledCategoryContainer_sudjyuf .StyledCategoryContainer_sudjyuf .StyledCategoryTitle_snb6src{font-weight:500;}.StyledCategoryContainer_sudjyuf.cssWithExpanded_cscgbn8 [data-in-view-start="false"]{display:none;}.StyledCategoryContainer_sudjyuf.cssWithExpanded_cscgbn8.cssIsExpanded_c1iktpus [data-in-view-start="false"]{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
